[wrap-comparative]{
    margin-bottom: 20px;

    [col]{
        width:101.2%;
        display:flex;
        align-items: center;
        justify-content: flex-start;
        gap:10px;
        margin-bottom: 10px;
        flex-wrap: wrap;

        input{
            flex: 1;
        }
        button{
            border:1px solid #e21d1d;
            width: 38px;
            height: 38px;
            box-sizing: border-box;
        }

        .sh-validator-feedback{
            flex:0 0 100%;
        }
    }

}
[add]{
    background-color: transparent;
    width:100%;
    height:42px;
    border:1px dashed #cecece;
    cursor: pointer;

    &:hover{
        border-color: #0f50fa;
    }
}
.multiselect{
    width: 101.2%;
}
[checkbox-box]{
    padding:7px 0;
}