$breakpoints: (
    xs: (from: 0, to: 567px),
    sm: (from: 568px, to: 767px),
    md: (from: 768px, to: 1023px),
    lg: (from: 1024px, to: 1279px),
    xl: (from: 1280px, to: 7680px)
);

[ui-text--align="left"] { text-align: left;} //@if-use-mod:ui-text--align-left
[ui-text--align="center"] { text-align: center;} //@if-use-mod:ui-text--align-center
[ui-text--align="right"] { text-align: right;} //@if-use-mod:ui-text--align-right

@mixin mq($breakpoint) {
    $cur: map-get($breakpoints, $breakpoint);
    
    @media screen and (min-width: map-get($cur, 'from')) and (max-width: map-get($cur, 'to')) {
        @content;
    }
}

@mixin display-in ($prefix, $value) {
    [ui-display--#{$prefix}="#{$value}"] {
        display: $value;
    }
}

@include mq('xs') {
    @include display-in('xs', none);         //@if-use-mod:ui-display--xs-none
    @include display-in('xs', inline);       //@if-use-mod:ui-display--xs-inline
    @include display-in('xs', inline-block); //@if-use-mod:ui-display--xs-inline-block
    @include display-in('xs', block);        //@if-use-mod:ui-display--xs-block    
    @include display-in('xs', table);        //@if-use-mod:ui-display--xs-table
    @include display-in('xs', table-cell);   //@if-use-mod:ui-display--xs-table-cell
    @include display-in('xs', table-row);    //@if-use-mod:ui-display--xs-table-row)
    @include display-in('xs', flex);         //@if-use-mod:ui-display--xs-flex
    @include display-in('xs', inline-flex);  //@if-use-mod:ui-display--xs-inline-flex
}

@include mq('sm') {
    @include display-in('sm', none);         //@if-use-mod:ui-display--sm-none
    @include display-in('sm', inline);       //@if-use-mod:ui-display--sm-inline
    @include display-in('sm', inline-block); //@if-use-mod:ui-display--sm-inline-block
    @include display-in('sm', block);        //@if-use-mod:ui-display--sm-block    
    @include display-in('sm', table);        //@if-use-mod:ui-display--sm-table
    @include display-in('sm', table-cell);   //@if-use-mod:ui-display--sm-table-cell
    @include display-in('sm', table-row);    //@if-use-mod:ui-display--sm-table-row)
    @include display-in('sm', flex);         //@if-use-mod:ui-display--sm-flex
    @include display-in('sm', inline-flex);  //@if-use-mod:ui-display--sm-inline-flex
}

@include mq('md') {
    @include display-in('md', none);         //@if-use-mod:ui-display--md-none
    @include display-in('md', inline);       //@if-use-mod:ui-display--md-inline
    @include display-in('md', inline-block); //@if-use-mod:ui-display--md-inline-block
    @include display-in('md', block);        //@if-use-mod:ui-display--md-block    
    @include display-in('md', table);        //@if-use-mod:ui-display--md-table
    @include display-in('md', table-cell);   //@if-use-mod:ui-display--md-table-cell
    @include display-in('md', table-row);    //@if-use-mod:ui-display--md-table-row)
    @include display-in('md', flex);         //@if-use-mod:ui-display--md-flex
    @include display-in('md', inline-flex);  //@if-use-mod:ui-display--md-inline-flex
}

@include mq('lg') {
    @include display-in('lg', none);         //@if-use-mod:ui-display--lg-none
    @include display-in('lg', inline);       //@if-use-mod:ui-display--lg-inline
    @include display-in('lg', inline-block); //@if-use-mod:ui-display--lg-inline-block
    @include display-in('lg', block);        //@if-use-mod:ui-display--lg-block    
    @include display-in('lg', table);        //@if-use-mod:ui-display--lg-table
    @include display-in('lg', table-cell);   //@if-use-mod:ui-display--lg-table-cell
    @include display-in('lg', table-row);    //@if-use-mod:ui-display--lg-table-row)
    @include display-in('lg', flex);         //@if-use-mod:ui-display--lg-flex
    @include display-in('lg', inline-flex);  //@if-use-mod:ui-display--lg-inline-flex
}

@include mq('xl') {
    @include display-in('xl', none);         //@if-use-mod:ui-display--xl-none
    @include display-in('xl', inline);       //@if-use-mod:ui-display--xl-inline
    @include display-in('xl', inline-block); //@if-use-mod:ui-display--xl-inline-block
    @include display-in('xl', block);        //@if-use-mod:ui-display--xl-block    
    @include display-in('xl', table);        //@if-use-mod:ui-display--xl-table
    @include display-in('xl', table-cell);   //@if-use-mod:ui-display--xl-table-cell
    @include display-in('xl', table-row);    //@if-use-mod:ui-display--xl-table-row)
    @include display-in('xl', flex);         //@if-use-mod:ui-display--xl-flex
    @include display-in('xl', inline-flex);  //@if-use-mod:ui-display--xl-inline-flex
}