@import '../../theme';

[ui-heading] {
    text-transform:capitalize;
	font-weight: bold;
	line-height: 1.2;
    margin-bottom: 1rem;

    i{
        color:$system-color;
    }

	&[ui-heading--size="1"] {font-size: 40px;} // @if-use-mod:ui-heading--size-1 

    &[ui-heading--size="3"] {font-size: 28px;} // @if-use-mod:ui-heading--size-3
    &[ui-heading--size="4"] {font-size: 24px;} // @if-use-mod:ui-heading--size-4
    &[ui-heading--size="5"] {font-size: 20px;} // @if-use-mod:ui-heading--size-5
    &[ui-heading--size="6"] {font-size: 16px;} // @if-use-mod:ui-heading--size-6
    
    &[ui-heading--size="5"] {
        display:flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding:20px 0;
        position: relative;

        &:before{
            content:'';
            background-color:$color-info;
            width:8px;
            height:8px;
            margin-right:10px;
            border-radius: 50px;
        }
    }

    &[ui-heading--title-page]{
        background-color:#f8f8f8;
        width:calc(104% + $default-padding * 4);
        display:flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        border-bottom:1px solid #e9e9e9;
        padding: $default-padding * 4 $default-padding * 4;
        margin-bottom: $default-padding * 2;
        margin-left:-$default-padding * 4;
        margin-right:-$default-padding * 4;
        box-sizing: border-box;

        [col]{
            font-size:28px;
            color:$base-title-color;
            display:flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;

            i{
                font-size: 24px;
                color:$base-title-color;
                background-color:#e9e9e9;
                width:48px;
                height:48px;
                display:flex;
                align-items: center;
                justify-content: center;
                margin-right:10px;
                border-radius: 50px;
                box-sizing: border-box;
            }
        }

        [ui-btn]{
            height:38px;
            border:0;
            padding:0 20px;
            border-radius:50px;
            position: relative;
            left:-2rem;

            i{
                margin-right:5px;
            }
        }

    }
}