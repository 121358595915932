@import '../../../theme';

[ui-layout-auth] {
	background-color: $color-primary;
	display:flex;
	align-items: center;
	justify-items: center;
	height: 100%;
	padding: 80px 0;
	overflow: auto;

	&:before{
		content:'';
		background-image:url('../images/bg-login.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: contain;
		width:110vw;
		height:110vh;
		display: block;
		position: fixed;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		z-index:0;
		filter:blur(10px);
	}

	//@if-use-mod-start:ui-layout-auth--block-brand
	[ui-layout-auth--block="brand"] {
		border-bottom: 1px solid #efefef;
		clear: both;
		display: block;
		padding: $default-padding * 2 0;
		text-align: center;

		img {
			display: inline-block;
			height: 20px;
			width: 20px;
			vertical-align: middle;
		}

		svg{
			fill:$auth-layout-logo-color;
			height:24px;
		}

		.brand-text {
			font-weight: 900;
			font-size: 28px;
			line-height: 28px;
			letter-spacing: -1.5px;
			vertical-align: middle;
		}
	}
	//@if-use-mod-end

	[ui-layout-auth--block="content"] {
		background-color: $auth-layout-box-background;
		border-radius: $default-border-radius;
		width:100%;
		max-width: 420px;
		margin: 0 auto;
		padding: $default-padding * 2;
		color: $auth-layout-box-text-color;
		box-shadow: 0 0px 30px rgba(0,0,0,0.2);
		position: relative;
		z-index: 1;

		h1,h2,h3,h4,h5,h6,a,p,span,label {
			color: $auth-layout-box-text-color;
		}

		[ui-container] {
			padding: $default-padding * 2 $default-padding * 2 $default-padding * 2 ;
		}

		[ui-btn] {
			color:#fff;
			background-color: $color-primary;
			width:100%;
			height:42px;
			padding: 0 24px;
		}
	}

}