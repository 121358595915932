@import '../../theme';

[ui-input-group] {
	margin-bottom: $default-margin;

	&.sh-validator-has-error{
		input, select {
			border-color: $color-danger;
		}

		label {
			color: $color-danger;
		}

		.sh-validator-feedback {
			color: $color-danger;
			font-size: 12px;
			display: block;
			padding: calc($default-padding / 2)  0;
		}
	}

	span{
		font-size:14px;
		color:#111;
	}
}