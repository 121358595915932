@import '../../theme';

[ui-loader] {
	width:100%;
	height: 100%;
	min-height:320px;
	position: relative;

	.floater {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
	}

	.spinner,
	.spinner:after {
		border-radius: 50%;
		width: 32px;
		height: 32px;
	}

	.spinner {
		margin: 0 auto;
		font-size: 32px;
		position: relative;
		text-indent: -9999em;
		border-top: $spinner-border-size solid $spinner-border-inactive-color;
		border-right: $spinner-border-size solid $spinner-border-inactive-color;
		border-bottom: $spinner-border-size solid $spinner-border-inactive-color;
		border-left: $spinner-border-size solid $spinner-border-active-color;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-animation: spin 1.1s infinite linear;
		animation: spin 1.1s infinite linear;
	}

	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	
	@keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

}