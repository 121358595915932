@import '../../theme';

[ui-table] {
	width: 100%;
	box-sizing: border-box;
	border-spacing:0;
	border-collapse: collapse;

	thead,
	tbody,
	tfoot{
		width:100%;
	}

	tr{
		&:nth-child(even) {
			background-color: #f5f5f5;
		}
	}

	td, th {
		text-align: left;
		min-height:52px;
		padding: $default-padding;
		border-bottom: 1px solid $default-border-color;
	}

	[ui-table-action] {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: row;

		[ui-btn] {
			&+ [ui-btn] {
				margin-left: $default-margin;
			}
		}
	}

	[ui-table-feedback] {
		text-align: center;
		padding: $default-padding * 2;
		color: lighten($base-text-color, 20%);
	}
}