@import '../../theme';

.dashboardBox {
    .dashboardItens {
        width:calc((100% / 12 * 2.4) - 8px)!important;
        cursor: pointer;
        padding: 2.5rem;
        text-align: center;
        display:flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        border:2px solid $system-color;
        border-radius: $default-border-radius;

        i{
            font-size:32px;
            color:$system-color;
            margin-right:20px;
        }

        [text]{
            display:flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
        }
        
        h3{
            color:$system-color;
            margin-bottom:0;
        }

        &:hover{
            background:lighten($system-color, 5%);
            transition:0.2s all linear;

            h3,
            span,
            i{
                color:#fff;
            }
        }
    }
}