@import '../../theme';

[ui-icon] {
	&:after,
	&:before {
		font-family: Flaticon;
		font-style: normal;
		display: inline-block;
	}





























	&[ui-icon--name="book"]:before { content: "\f11c"; } //@if-use-mod:ui-icon--name-book
























	&[ui-icon--name="camera"]:before { content: "\f135"; } //@if-use-mod:ui-icon--name-camera

	&[ui-icon--name="car"]:before { content: "\f137"; } //@if-use-mod:ui-icon--name-car

	&[ui-icon--name="chart"]:before { content: "\f139"; } //@if-use-mod:ui-icon--name-chart





	&[ui-icon--name="checked"]:before { content: "\f13f"; } //@if-use-mod:ui-icon--name-checked













	&[ui-icon--name="clock"]:before { content: "\f14d"; } //@if-use-mod:ui-icon--name-clock
























































































	&[ui-icon--name="external-link"]:before { content: "\f1a6"; } //@if-use-mod:ui-icon--name-external-link
	&[ui-icon--name="eye"]:before { content: "\f1a7"; } //@if-use-mod:ui-icon--name-eye






	&[ui-icon--name="file"]:before { content: "\f1ae"; } //@if-use-mod:ui-icon--name-file








	&[ui-icon--name="flag"]:before { content: "\f1b7"; } //@if-use-mod:ui-icon--name-flag


























































	&[ui-icon--name="left-arrow-3"]:before { content: "\f1f2"; } //@if-use-mod:ui-icon--name-left-arrow-3











































































	&[ui-icon--name="paper-plane-1"]:before { content: "\f23e"; } //@if-use-mod:ui-icon--name-paper-plane-1





	&[ui-icon--name="pencil"]:before { content: "\f244"; } //@if-use-mod:ui-icon--name-pencil
















	&[ui-icon--name="plus-circle-1"]:before { content: "\f255"; } //@if-use-mod:ui-icon--name-plus-circle-1






	&[ui-icon--name="printer"]:before { content: "\f25c"; } //@if-use-mod:ui-icon--name-printer

































































	&[ui-icon--name="sign-out"]:before { content: "\f29e"; } //@if-use-mod:ui-icon--name-sign-out













	&[ui-icon--name="spinner"]:before { content: "\f2ac"; } //@if-use-mod:ui-icon--name-spinner
























































	&[ui-icon--name="trash"]:before { content: "\f2e5"; } //@if-use-mod:ui-icon--name-trash















	&[ui-icon--name="up-arrow-6"]:before { content: "\f2f5"; } //@if-use-mod:ui-icon--name-up-arrow-6





	&[ui-icon--name="user"]:before { content: "\f2fb"; } //@if-use-mod:ui-icon--name-user




	&[ui-icon--name="users"]:before { content: "\f300"; } //@if-use-mod:ui-icon--name-users











	&[ui-icon--name="wrench"]:before { content: "\f30c"; } //@if-use-mod:ui-icon--name-wrench





}