@import '../../theme';
@import '../../utils';

@function col-width ($column) {
    @return calc((100% / #{$columns} * #{$column}) - #{$gutter});
}

@function offset-width ($column) {
    @return calc((100% / #{$columns} * #{$column}) + ($gutter / 2));
}

@function breakpoint-prefix ($prefix) {
    @if ($prefix != '') {
        @return '-#{$prefix}';
    } @else {
        @return '';
    }
}

@mixin columm-size ($size, $prefix: '') {
    &[ui-col#{breakpoint-prefix($prefix)}--size="#{$size}"] {
        width: col-width($size);
        margin: 0 calc($gutter / 2);
    }
}

@mixin offset-size ($size, $prefix: '') {
    &[ui-col#{breakpoint-prefix($prefix)}--offset="#{$size}"] {
        margin-left: offset-width($size);
    }
}


[ui-row] {
    width:100%;
    display:flex;
    flex-direction: row;
    margin: 0 calc($gutter / 2 * -1) 1rem;
    position: relative;

    &:last-of-type{
        margin-bottom:0;
    }

    [ui-col] {
        float: left;
        position: relative;
        
        @include columm-size(1); //@if-use-mod:ui-col--size-1
        @include columm-size(2); //@if-use-mod:ui-col--size-2
        @include columm-size(3); //@if-use-mod:ui-col--size-3
        @include columm-size(4); //@if-use-mod:ui-col--size-4
        @include columm-size(5); //@if-use-mod:ui-col--size-5
        @include columm-size(6); //@if-use-mod:ui-col--size-6

        @include columm-size(8); //@if-use-mod:ui-col--size-8

        @include columm-size(10); //@if-use-mod:ui-col--size-10

        @include columm-size(12); //@if-use-mod:ui-col--size-12





        @include offset-size(5); //@if-use-mod:ui-col--offset-5







        @include mq('xs') {
























        }

        @include mq('sm') {
























        }

        @include mq('md') {
























        }

        @include mq('lg') {
























        }

        @include mq('xl') {
























        }
    }
}