@import '../../theme';

textarea {
    resize: none;
}

[ui-heading][ui-heading--size="5"] {
    margin-top: 1rem;
}

.images {
    .imgContainer {
        position: relative;
        width: 100%;
        height: 260px;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    } 
}

.tableChecklist {
    [ui-row] {
        padding: 10px;
    }

    .tableRecords {
        [ui-input-group] {
            margin: 0;
        }

        .answerTitle {
            font-size:16px;
            padding-top: 7px;
        }

        label {
            margin-right: 10px;
        }
    }

    .tableRecords:hover {
        background-color: #efefef;
    }

}

.remove-installer {
    width:48px;
    height: 38px !important;
    line-height: 38px !important;
}

.info-text{
    background-color: rgba(0,0,0,0.05);
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding:32px;
    border-radius:4px;

    i{
        font-size: 32px;
        color:rgba(0,0,0,0.4);
    }
    span{
        color:rgba(0,0,0,0.4);
    }
}