@import '../../theme';

[ui-pagination] {
	box-sizing: border-box;
	padding-top:$default-padding * 4;

	ul {
		li {
			display: inline-block;
			user-select: none;

			[ui-pagination-number] {
				background-color: $color-default;
				border-radius: $default-border-radius;
				font-size:16px;
				cursor: pointer;
				display: inline-block;
				width: 38px;
				height: 30px;
				line-height: 30px;
				padding: 0 $default-padding;
				margin-right: 5px;
				text-align: center;
				min-width: 30px;
			}

			&.current,
			&:not(.ellipsis):hover {
				[ui-pagination-number] {
					background-color: $color-success;
					color: #fff;
					transition: 0.2s all linear;
				}
			}

			&.ellipsis {
				[ui-pagination-number] {
					cursor: unset;
					background-color: lighten($color-default, $btn-hover-lighten);
					color: lighten($base-text-color, $btn-hover-lighten);
				}
			}
		}
	}
}