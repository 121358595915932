@import "../../theme";

[ui-modal-content] {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding:20px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow-y: auto;

  .modal-box {
    background-color: #fff;
    width: 100%;
    max-width: 740px;
    border-radius: 8px;
    filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.1));
    margin-top: 60px;
  }
  .modal-title,
  .modal-content {
    padding: 16px;
    box-sizing: border-box;
  }
  .modal-title {
    padding-bottom: 0;
    h4 {
      font-size: 16px;
    }
  }
  .modal-content {
    p {
      font-size: 14px;
    }
  }
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 0 16px 16px;
  }
  .checkbox {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    display: block;
    margin-top: 10px;
    cursor: pointer;
  }
}
