@import '../../theme';

.filters-companies {
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: $default-padding * 3;
    margin-bottom: $default-margin * 3!important;
    box-sizing: border-box;

    [ui-col]{
        flex:1;
    }

    [ui-input] {
        width: 100%;
    }
    .item-input{
        .form-control{
            padding:0;
        }

        .input-control{
            font-weight: 400;
            font-size: 14px;
            color: #343434;
            height:inherit;
            background-color:transparent;
            border:0;
            position: relative;
            z-index:1;

            &::placeholder {
                color:rgba(0,0,0,0.15);
            }
        }
    }
}