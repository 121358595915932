[ui-printer-btn] {
    margin-left: 8px;
    margin-right: 8px;

    i{
        color:#fff;
    }

    [printer-btn]{
        background-color: #5366f4;
        border:0;

        &:hover{
            background-color: #495add;
        }

        &[loading="PROCESSING"] {
            color:#494949;
            pointer-events: none;
            position:relative;
            background: linear-gradient(270deg, #e0e0e0, #c5c5c5);
                background-size: 400% 400%;
                -webkit-animation: AnimationName 2s ease infinite;
                -moz-animation: AnimationName 2s ease infinite;
                animation: AnimationName 2s ease infinite;

            i{
                color:#494949;
            }

            &:hover{
                pointer-events: none;
            }

            &:before{
                content:'Criando PDF';
                width:70px;
                display:block;
                padding:0 6px;
                border-radius:3px;
                position: relative;
                top:0;
                left:0;
                box-sizing: border-box;
            }
        }
        &[loading="PROCESSED"] {
            color:#ffffff;
            font-weight: bold;
            background-color: #56b052;
            position:relative;

            i{
                color:#ffffff;
                z-index: 100;
            }

            &:hover{
                background-color: #4ea249;
            }

            &:before{
                width:70px;
                display:block;
                padding:0 6px;
                border-radius:3px;
                position: relative;
                top:0;
                left:0;
                box-sizing: border-box;
            }
        }
        &[loading="UPDATED"] {
            color:#ffffff;
            font-weight: bold;
            background-color: #56b052;
            position:relative;

            i{
                color:#ffffff;
                z-index: 100;
            }

            &:hover{
                background-color: #4ea249;
            }

            &:before{
                width:85px;
                display:block;
                padding:0 6px;
                border-radius:3px;
                position: relative;
                top:0;
                left:0;
                box-sizing: border-box;
            }
        }
        &[loading="ERROR"] {
            color:#ffffff;
            font-weight: bold;
            background-color: #e17575;
            position:relative;

            &:hover{
                background-color: #cf6d6d;
            }

            i{
                color:#ffffff;
                z-index: 100;
            }

            &:before{
                content:'Algo de errado. Tente novamente.';
                text-align: left;
                line-height:10px;
                width:100px;
                display:block;
                padding:0 6px;
                border-radius:3px;
                position: relative;
                top:0;
                left:0;
                box-sizing: border-box;
            }
        }
    }

    [spinner]{
        animation: spinner 1s infinite linear;
    }
}

@keyframes spinner{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}