@import '../../theme';

[ui-checkbox] {
	width:22px;
	height:22px;
	line-height: 30px;
	font-size: 14px;
	padding: 0 $default-padding;
	border: 1px solid $default-border-color;
	background-color: #fff;
	border-radius: $default-border-radius;
}

[ui-input], .date-time-picker {
	border-radius: 0;
	height: 38px;
	line-height: 30px;
	font-size: 14px;
	padding: 0 $default-padding;
	border: 1px solid $default-border-color;
	background-color: #fff;
	border-radius: $default-border-radius;

	&:hover{
		border-color:lighten($border-input, 40%);
	}
	&:focus{
		border-color:$border-input;
	}


	.field,
	.field-input{
		height: 38px;
		min-height: 38px!important;
	}
	.field-input{
		border: 1px solid $default-border-color;
	}

	//@if-use-mod-start:ui-input--type-select
	&[ui-input--type="select"] {
	    -webkit-appearance: none;
	    border-radius: 0px;
	    background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPScxLjEnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycgeG1sbnM6eGxpbms9J2h0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsnIHdpZHRoPScyNCcgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0Jz48cGF0aCBmaWxsPScjNDQ0JyBkPSdNNy40MDYgNy44MjhsNC41OTQgNC41OTQgNC41OTQtNC41OTQgMS40MDYgMS40MDYtNiA2LTYtNnonPjwvcGF0aD48L3N2Zz4=");
	    background-position: center  right 4px;
	    background-repeat: no-repeat;
		background-size: 19px;
		border-radius: $default-border-radius;
	}
	//@if-use-mod-end

	//@if-use-mod-start:ui-input--type-textarea
	&[ui-input--type="textarea"] {
	    padding-top: 5px;
	    padding-bottom: 5px;
	    line-height: 20px;
	    height: auto!important;
		min-height:180px;
	}
	//@if-use-mod-end

	//@if-use-mod-start:ui-input--type-checkbox
	&[ui-input--type="checkbox"] {
		display: inline-block;
		margin-right: $default-margin - 2px;
		width: 14px!important;
		height: 14px!important;

		+ label {
			display: inline-block;
			font-weight: normal;
			line-height: 30px;
		}
	}
	//@if-use-mod-end

	//@if-use-mod-start:ui-input--type-radio
	&[ui-input--type="radio"] {
		display: inline-block;
		margin-right: $default-margin  - 2px;
		width: 14px!important;
		height: 14px!important;

		+ label {
			display: inline-block;
			font-weight: normal;
			line-height: 30px;
		}
	}
	//@if-use-mod-end
}

[ui-checkbox-group] {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	align-self: center;
	margin-left: 0.3rem;
}

[ui-input-group] {
	position: relative;

	[ui-input] {
		width: 100%;
	}
	[input_other]{
		width: 100%;
		position: absolute;
		bottom:0;
		left:0;
		z-index:10;

		[save-input]{
			width:32px;
			height:32px;
			display:flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			bottom:3px;
			right:0rem;
			z-index: 10;
			cursor:pointer;

			i{
				font-size:16px;
				color:$color-success;
			}
		}
		
		[clean-input]{
			width:32px;
			height:32px;
			display:flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			bottom:3px;
			right:31px;
			z-index: 10;
			cursor:pointer;

			i{
				font-size:16px;
				color:$color-danger;
			}
		}

		input{
			position:relative;
			padding-right:66px;
		}
	}
}

input, select, textarea {
	&:disabled {
		background-color: #f8f8f8;
		cursor: not-allowed!important;
	}

}