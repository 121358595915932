.page-404 {
	.content {
		margin: 120px auto;
		max-width: 320px;
		text-align: center;
		

		img {
			width: 72px;
			vertical-align: middle;
			margin-right: 16px;
		}

		h1 {
			font-size: 72px;
			display: inline-block;
			vertical-align: middle;
		}

		p {
			font-weight: bold;
			display: block;
		}

		.back {
			margin: 16px;
			display: block;
		}
	}
}