[tab-nav]{
    display: flex;
    align-items: center;
    justify-content:flex-start;
    border-radius: 6px 6px 0 0;
    overflow: hidden;

    button{
        font-size:16px;
        color:#d7d7d7;
        background:transparent;
        height:48px;
        display: flex;
        align-items: center;
        justify-content:center;
        border:1px solid #d7d7d7;
        border-left: 0;
        border-bottom:0;
        padding:0 20px;
        cursor: pointer;

        @media screen and (min-width: 920px) and (max-width: 1025px){
            font-size:14px;
        }
        @media screen and (min-width: 742px) and (max-width: 920px){
            font-size:14px;
        }
        @media screen and (max-width: 741px){
            font-size:14px;
        }
        

        &:hover{
            color:#bbbbbb;
            background:#f7f7f7;
            

            span{
                color:#bbbbbb;
            }
        }
        
        &:first-of-type{
            border-radius:6px 0 0 0;
            border-left: 1px solid #d7d7d7;
        }
        &:last-of-type{
            border-radius:0 6px 0 0;
        }

        &[current]{
            color:#fff;
            background:#1c50d4;
            border-color: #1c50d4;
            pointer-events: none;

            &:first-of-type{
                border-left: 1px solid #1c50d4;
            }

            span{
                background:#fff;
                color:#1c50d4;
            }
        }

        span{
            font-size:16px;
            color:#d7d7d7;
            background:lighten(#d7d7d7, 10%);
            width:28px;
            height:28px;
            display: flex;
            align-items: center;
            justify-content:center;
            margin-right:10px;
            border-radius:50px;

         
            @media screen and (max-width: 640px){
                display:none;
            }
            
        }
    }
}
[tab-content]{
    background:#fff;
    width:100%;
    border:1px solid #d7d7d7;
    padding:20px;
    border-radius:0 6px 6px 6px;
    box-sizing: border-box;

    [item-tab]{
        display:none;

        &[current]{
            display:block;
        }

        h5{
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}