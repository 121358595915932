@import '../../../theme';
@import '../../../utils';

[ui-layout-admin] {
	height: 100%;
	background-color: $admin-layout-default-background;

	[ui-layout-admin--block="nav"] {
		padding: $default-padding * 2 $default-padding * 4;
		background-color: $admin-layout-nav-background;
		color: $admin-layout-nav-color;
		height: 60px;
	
		.menu-toggler {
			display: none;
		}

		//@if-use-mod-start:ui-layout-admin--block-brand
		[ui-layout-admin--block="brand"] {
			display: block;
			// float: left;
			width: 180px;
			position: relative;
			top: 7px;

			img {
				width: 20px;
				height: 20px;
				display: inline-block;
				vertical-align: middle;
			}

			svg{
				fill: #ffffff;
				height: 16px;
			}

			.brand-text {
				line-height: 28px;
				font-weight: 900;
				font-size: 28px;
				letter-spacing: -1.5px;
				vertical-align: middle;
				color: $admin-layout-nav-color;
			}
		}
		//@if-use-mod-end

		//@if-use-mod-start:ui-layout-admin--block-user-account
		[ui-layout-admin--block="user-account"] {
			width: 180px;
			position: absolute;
			top: 0;
			right: $default-margin * 4;
			padding: $default-padding * 2 0;

			[flex]{
				display:flex;
				align-items: center;
				align-content: center;
				justify-content: flex-end;
			}

			img {
				// float: right;
				width: 28px;
				height: 28px;
				display: block;
				border-radius: 50%;
				border: 1px solid $default-border-color;
				background: #fff
			}

			.user-info {
				width: 152px;
				// float: right;
			}

			.user-name, .user-role {
				font-size: 14px;
				display: block;
				line-height: 16px;
				padding-right: $default-padding;
				text-align: right;
				color: $admin-layout-nav-color;
			}

			.user-role {
				text-transform: uppercase;
				font-size: 10px;
				line-height: 12px;
			}

			.user-name.norole {
				line-height: 28px;
			}

			.dropdown {
				visibility: hidden;
				position: absolute;
				right: 0;
				top: 50px;
				background-color: #fff;
				border-radius: $default-border-radius;
				text-align: left;
				min-width: 150px;
				padding: $default-padding 0;
				box-shadow: 1px 1px 16px 0px rgba(0,0,0,0.1);

				a {
					display: block;
					padding: $default-padding $default-padding * 2;

					&:hover {
						background-color: $admin-layout-nav-hover-background;
					}

					[ui-icon] {
						margin-right: $default-margin;
					}
				}

				&::after {
					position: absolute;
					content: '';
					display: block;
					height: 10px;
					width: 10px;
					border: 5px solid transparent;
					border-bottom-color: #fff;
					box-sizing: border-box;
					top: -10px;
					right: $default-margin;
				}
			}

			&:hover {
				.dropdown {
					visibility: visible;
				}
			}
		}
		//@if-use-mod-end

		&:after{
			content: '';
			display: block;
			clear: both;
		}
	}

	[ui-layout-admin-sidebar] {
		width: $admin-layout-sidebar-width;
		height: calc(100vh - 60px);
		float: left;
		padding: $default-padding * 4;
		position: relative;

		.menu {
			ul > li {
				a {
					font-size: 16px;
					line-height: 36px;
				}

				[ui-icon] {
					font-size: 16px;
					color:$admin-layout-sidebar-icon-desktop;
					margin-right: $default-margin;
				}

				&.separator {
					text-transform: uppercase;
					font-size: 12px;
					line-height: 12px;
					margin-bottom: 12px;
					font-weight: 500;
					display: block;
				}

				& + .separator {
					margin-top:  $default-margin *2 ;
				}
			}
		}

		
	}

	[ui-layout-admin-content] {
		width: calc(100% - #{$admin-layout-sidebar-width});
		height: calc(100vh - 60px);
		overflow: auto;
		scroll-behavior: smooth;


		[ui-layout-admin--block="main-content"] {
			padding: $default-padding * 4 $default-padding * 2;
			
			[ui-container] {
				// background-color: #fff;
				background: rgb(239,239,239);
				background: linear-gradient(0deg, rgba(239,239,239,1) 0%, rgba(255,255,255,1) 10%); 
				min-height: calc(100vh - 132px);
				border-radius: $default-border-radius;
				padding:0 $default-padding * 4;	
				box-shadow: 0 -11px 14px rgba(0,0,0,0.03);		
				overflow: hidden;
			}
		}
	}

	//@if-use-mod-start:ui-layout-admin--mobile-true
	&[ui-layout-admin--mobile="true"] {
		@include mq('xs') {
			height: calc(100vh - 50px);

			[ui-layout-admin--block="nav"] {
				padding: $default-padding;
				line-height: 34px;
				height: 50px;

				.menu-toggler {
					display: block;
					width: 40px;
					padding: $default-padding;
					float: left;
					height: 34px;
					margin-right: $default-margin;

					.bar {
						width: 100%;
						display: block;
						background-color: #fff;
						height: 3px;

						&+.bar {
							margin-top: 4px;
						}
					}
				}				
			}

			[ui-layout-admin--block="user-account"] {
				display: none;
			}


			[ui-layout-admin-sidebar] {
				padding: $default-padding * 2;
				position: absolute;
				background-color: $admin-layout-default-background;
				z-index: 10;
				display: none;
				height: calc(100vh - 50px);

				&.open {
					display: block;
				}
			}

			[ui-layout-admin-content] {
				padding: $default-padding;
				width: 100%;
				background-color: #fff;

				[ui-layout-admin--block="main-content"] {
					padding: 0;

					[ui-container] {
						padding: $default-padding;
					}
				}
			}
		}
	}
	//@if-use-mod-end

	[scrollPageTop]{
		background:$color-primary;
		width:48px;
		height:48px;
		display: flex;
		align-items: center;
		justify-content: center;
		border:0;
		border-radius:50%;
		position: fixed;
		bottom:-1.5rem;
		right:3rem;
		z-index:600;
		cursor: pointer;
		box-shadow: 0 4px 6px rgba(0,0,0,0.1);
		opacity:0;
		visibility: hidden;
		transition:0.1s all ease-in-out;

		i{
			font-size:20px;
			color:#fff;
		}

		&[active]{
			bottom:1.5rem;
			opacity:1;
			visibility: visible;
			transition:0.2s all ease-in-out;
		}
	}
}