@import '../../theme';

.filters {
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: $default-padding * 4;
    margin-bottom: $default-margin * 4!important;
    box-sizing: border-box;

    [ui-col]{
        flex:1;
    }

    [ui-input] {
        width: 100%;
    }
    .item-input{
        // height:30px;

        .form-control{
            padding:0;
        }

        .input-control{
            font-weight: 400;
            font-size: 14px;
            color: #343434;
            height:inherit;
            background-color:transparent;
            border:0;
            position: relative;
            z-index:1;

            &::placeholder {
                color:rgba(0,0,0,0.15);
            }

            &.date-time-picker{
                padding:0!important;

                .field{

                    input{
                        background-color:transparent;
                        height:inherit;
                        border-radius:0;

                        &::placeholder {
                            color:#000;
                        }
                    }
                }

                .field-input{
                    padding-left: 6px;
                    padding-right: 15px;
                }

                .header-picker-year{
                    span {
                        div {
                            color: #ffffff!important;
                        }
                    }
                }

                .header-picker{
                    span{
                        color:#ffffff!important;
                    }
                }
            }
        }
    }
}

.denatran-null {
    background-color:#f8f6c5 !important;
}