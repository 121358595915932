[ui-update-password] {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
}

[ui-input-checkbox-group] {
    width: 20%;
    min-width: 300px;
    input {
        width: 100%;
        margin-bottom: 3%;
    };

    button {
        width: 50%;
        height: 40px;
    }

    &[disabled]{
        opacity:0.2;
        
        input{
            pointer-events: none;
        }
    }
}

[ui-sub-title] {
    width: 20%;
    min-width: 300px;
    margin-bottom:20px;

    h2 {
        font-weight: normal;
    };
}

[ui-info-password] {
    background-color: #f8f8f8;
    width: 20%;
    min-width: 300px;
    border:1px solid #e7e7e7;
    border-radius:3px;
    padding:16px;
    margin-bottom: 16px;
    position:relative;

    p{
        font-size:12px;
        margin-bottom: 2px;
    }
    & > span{
        font-size:12px;
        display:block;
        margin-bottom: 10px;
    }

    .icon-active{
        color:#5cbf85;
    }
    .icon-disabled{
        color:#cecece;
    }
}
[ui-progress]{
    width:100%;
    border-radius:3px;
    position:absolute;
    bottom:0;
    left:0;
    overflow: hidden;

    [progress] {
        background-color:rgba(0,0,0,0.1);
        width:100%;
        height:4px;
        overflow: hidden;
        
        span{
            width:0%;
            height:100%;
            display:block;
            transition:0.2s all ease-in-out;
        }
    }
}

.sh-validator-feedback{
    margin-bottom:10px;
    padding: 6px 0!important;
    box-sizing: border-box;
}