@import './utils';
@import './theme';
@import './components/toast/toast';
@import './vendors/application/vue-ctk-date-time-picker';
@import './vendors/application/vue-multiselect';
@import './components/tooltip/tooltip';

* {
	margin: 0;
	padding: 0;
	outline: 0;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	color: $base-text-color;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #app {
	height: 100%;
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
}

.text-right {
	text-align: right;
}

.divider{
	background-color: #efefef;
	width:100%;
	height:1px;
}

.multiselect__tag{
	background-color: #efefef;
	margin-bottom:0;
}
.multiselect__tag-icon{
	background-color: #eae4e4;

	&:hover{
		background-color: #ee4242;;
	}
	
	&:after{
		color:#ee4242;
	}
}
.multiselect__option{
	border-bottom: 1px solid #fff;
}
.multiselect__option--selected{
	&:after{
		font-weight: normal;
	}
}
.multiselect__option--selected.multiselect__option--highlight{
	background-color: #f3f3f3!important;
	color:#333;


	&:after{
		font-weight: normal;
		background-color: #f3f3f3!important;
		color:#d5250d;
		
	}
}
.multiselect__option--highlight{
	background-color: #f3f3f3!important;
	color:#333;

	&:after{
		font-weight: normal;
		background-color: #f3f3f3!important;
		color:#333;
	}
}