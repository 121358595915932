@import '../../theme';

[ui-label] {
	font-size:14px;
	font-weight:400;
	color:#333;
	display: block;
	line-height: 1;
	margin-bottom:0.5rem;

	b{
		font-weight:bold;
	}
}

[ui-label-checkbox] {
	font-size:14px;
	font-weight:400;
	color:#333;
	display: block;
	line-height: 1;
	margin-left: 0.5rem;

	b{
		font-weight:bold;
	}
}