@import '../../theme';

@mixin btn-color ($name, $background, $border, $color) {
	&[ui-btn--color="#{$name}"] {
		background-color: $background;
		border-color: $background;
		color: $color;

		&:hover {
			background-color: darken($background, $btn-hover-darken);
		}

		* {
			color: $color;
		}
	}
}

@mixin btn-size ($name, $multipler) {
	&[ui-btn--size="#{$name}"] {
		height: 32px;
		line-height: $btn-default-height * $multipler;
		font-size: $base-font-size * $multipler;
		padding: 0 $default-padding * 1.5 * $multipler;
	}
}


[ui-btn] {
	background-color: #fff;
	border: 1px solid $color-default;
	border-radius: $default-border-radius;
	color: $base-text-color;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	height: $btn-default-height;
	line-height: $btn-default-height;
	padding: 0 $default-padding * 1.5;
	border-radius:3px;

	&:hover {
		background-color: darken(#fff, $btn-hover-darken);
	}

	&:disabled {
		opacity:0.2;
		cursor:default;
		pointer-events: none;
	}

	// Colors 
	@include btn-color('primary', $color-primary, $color-primary,#FFFFFF); //@if-use-mod:ui-btn--color-primary 
	@include btn-color('info', $color-info, $color-info,#FFFFFF);       //@if-use-mod:ui-btn--color-info 
	@include btn-color('success', $color-success, $color-success,#FFFFFF); //@if-use-mod:ui-btn--color-success 
	@include btn-color('danger', $color-danger, $color-danger,#FFFFFF);   //@if-use-mod:ui-btn--color-danger 
	@include btn-color('warning', $color-warning, $color-warning,#FFFFFF); //@if-use-mod:ui-btn--color-warning 

	// Sizes

	@include btn-size('small', $btn-sm-multiplier);       //@if-use-mod:ui-btn--size-small




}

[ui-btn-group]{
	display:flex;
	align-items: center;
	justify-content:flex-start;
	flex-direction:row;

	[ui-btn]{
		font-size:16px;
		height:38px;
		padding:0 2rem;
		margin-right:$default-margin;
	}
}